<template>
  <b-container
    fluid="md"
    style="background: white"
    class="p-2"
  >
    <h4 class="my-4">
      Add New User
    </h4>
    <b-form @submit="saveUser">
      <b-form-group label="Email">
        <b-input v-model="email" />
      </b-form-group>
      <b-form-group label="Enter New Password">
        <b-input
          v-model="password"
          type="password"
        />
      </b-form-group>
      <b-form-group label="Role">
        <b-select
          v-model="role"
          :options="roles"
        />
      </b-form-group>
      <b-button
        variant="primary"
        type="submit"
      >
        Save
      </b-button>
    </b-form>

    <b-alert
      v-model="success"
      class="position-fixed fixed-bottom m-0 rounded-0"
      style="z-index: 2000;"
      variant="info"
      dismissible
    >
      User successfully added
    </b-alert>
  </b-container>
</template>

<script>
import { createUserService } from '@/services/user-service/userRequests';

export default {
    data: () => ({
        email: '',
        password: '',
        role: '',
        roles: [
            'admin',
            'user'
        ],
        error: false,
        success: false
    }),
    methods: {
        async saveUser(event) {
            try {
                event.preventDefault();
                const response = await createUserService({
                    email: this.email,
                    password: this.password,
                    role: this.role
                });
                if (response.data.code === 2000) {
                    this.error = false;
                    this.success = true;
                    window.location.href = '/users';
                } else {
                    this.success = false;
                    this.error = true;
                }
            } catch (err) {
                console.error(err);
                this.success = false;
                this.error = true;
            }
        }
    }
};
</script>